.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    /* Space between grid items */
  }
  
  .grid-item {
    
    text-align: center;
  }
  